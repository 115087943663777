// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { reaction } from 'mobx';
import userStore from './store/user-store';  // Импортируем store

import translationEn from './components/i118n/locales/en/translation.json';
import translationRu from './components/i118n/locales/ru/translation.json';
import translationUa from './components/i118n/locales/ua/translation.json';
import UserStore from "./store/user-store";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEn,
            },
            ru: {
                translation: translationRu,
            },
            ua: {
                translation: translationUa,
            }
        },
        lng: UserStore.langCode, // начальный язык из MobX
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

// Следим за изменением языка в MobX и обновляем i18n
reaction(
    () => UserStore.langCode, // наблюдаем за изменением lang_code
    (lang) => {
        i18n.changeLanguage(lang); // меняем язык в i18next
    }
);

export default i18n;
