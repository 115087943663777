import React from 'react'

const Taskspage = () => {

    return (
        <div className="taskspage">
            <div className="taskspage__main">
                <svg xmlns="http://www.w3.org/2000/svg" width="88" height="117" viewBox="0 0 88 117" fill="none">
                    <path
                        d="M4.84766 17.9004H81.6977V97.6504C81.6977 104.057 76.5042 109.25 70.0977 109.25H16.4476C10.0411 109.25 4.84766 104.057 4.84766 97.6504V17.9004Z"
                        fill="white"/>
                    <path
                        d="M0.498047 101.736C0.498047 105.52 1.24249 108.126 5.02642 110.049C12.7804 113.771 24.1943 116.5 39.3922 116.5C53.1013 116.5 68.113 114.577 79.7751 109.056C86.0403 106.079 87.5291 103.411 87.5291 96.8979V22.7695C87.5291 14.1471 83.7451 10.239 75.4328 10.239H70.9665V8.192C70.9665 3.10537 68.4851 0.5 63.7708 0.5C62.22 0.5 60.2969 0.810117 58.0638 1.61658C43.6104 6.39309 31.1419 9.7428 13.4008 9.7428H9.18259C3.72374 9.7428 0.498047 12.9684 0.498047 17.8069V101.736ZM18.4254 47.5823V31.1439C18.4254 30.4615 18.9216 29.9032 19.604 29.8412C31.0799 29.3449 40.8809 27.2978 51.3024 23.2657C51.9226 23.0176 52.9152 23.2657 52.9152 24.4444V41.8133C52.9152 42.2476 52.6671 42.6818 52.2328 42.8679C43.114 46.5898 31.4521 48.761 19.604 48.761C18.9836 48.761 18.4254 48.3888 18.4254 47.5823ZM22.2713 107.443C41.4392 106.885 58.2499 100.992 67.5547 93.9823C69.85 92.2454 70.9665 90.3846 70.9665 85.732V19.1717H75.4328C77.6039 19.1717 78.5964 20.2883 78.5964 22.8315V96.2775C78.5964 99.193 78.2243 100.248 75.4328 101.364C65.3836 105.706 52.1708 108.374 39.3922 108.436C33.8714 108.374 28.5365 108.188 22.2713 107.443Z"
                        fill="url(#paint0_linear_520_6051)"/>
                    <defs>
                        <linearGradient id="paint0_linear_520_6051" x1="58.4649" y1="139.336" x2="20.6109" y2="-19.8592"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#F44E17"/>
                            <stop offset="1" stop-color="#FFAC00"/>
                        </linearGradient>
                    </defs>
                </svg>
                <div className="taskspage__main__text">
                    <h1>Tasks</h1>
                    <p>Coming soon</p>
                </div>
            </div>

            <div className="taskpage__links">
                <a href="https://x.com/DeGuardVPN" rel="noreferrer" target='_blank'>Join Twitter</a>
                <a href="https://t.me/DeGuardVPN" rel="noreferrer" target='_blank'>Join Telegram</a>
            </div>
        </div>


    )
}

export {Taskspage}


