import axios from "axios";
import UserStore from "../../store/user-store";

const apiUrl = process.env.REACT_APP_API_URL;

export const SendConfigToUser = async (protocolId, configText) => {
    return axios.post(`${apiUrl}/v2/miniapp/send_config/`, {
        protocol_id: protocolId,
        tg_id: UserStore.telegramId,
        config_text: configText
    })
}