import React from "react";
import {observer} from "mobx-react-lite";
import {RouterIntro} from "../components/Router/RouterIntro";
import "./RouterPage.css";
import {RouterMain} from "../components/Router/RouterMain/RouterMain";
import RouterStore from "../store/router-store";

export const RouterPage = observer(() => {
    return (
        !RouterStore.routerIntro ? <RouterIntro/> : <RouterMain/>
    );
});
