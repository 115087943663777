import React, {useState} from 'react'
import style from './InitialCards.module.css'
import TimerToPoints from "../TimerToRealese/TimerToPoints";


export const InitialCard = () => {

    const [isPointsInfo, setPointsInfo] = useState(false)

    return (
        <>
            <div className={style.card_wrapper} onClick={() => setPointsInfo(!isPointsInfo)}>
                {isPointsInfo ? <div style={{height: "159.2px", display: "flex", alignItems: "center", justifyContent: "center"}} >
                    <p style={{fontSize: "13.4px", lineHeight: "1.5", textAlign: "justify"}}>

                        We realize that many people are waiting for the launch of the $DF points system and we want to be honest:
                        <br/>
                        it is important for us to make this functionality as high-quality and interesting for users as possible.
                        We want to avoid any bugs that might affect your experience, so we're delaying the release a bit.
                        <br/>
                        Thank you for your patience!

                    </p>
                    </div> :
                    <>
                        <TimerToPoints targetDate="2025-02-01T00:00:00"/>
                        <div className={style.soon}>
                            <div className={style.card_content}>
                                <div className={style.card_content__balance}>
                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1" y="1" width="36" height="36" rx="18"
                                              fill="url(#paint0_linear_294_7890)"/>
                                        <rect x="1" y="1" width="36" height="36" rx="18" stroke="white"
                                              strokeWidth="2"/>
                                        <g clipPath="url(#clip0_294_7890)">
                                            <path
                                                d="M19.5896 28.5C24.0001 28.5 26.9475 25.5211 26.9475 21.0368C26.9475 13.5947 20.6001 10.5 16.2633 10.5C15.4212 10.5 14.8738 10.8053 14.8738 11.3842C14.8738 11.6053 14.9791 11.8474 15.158 12.0579C16.1264 13.2158 17.1054 14.5737 17.1264 16.2369C17.1264 16.5842 17.1159 16.879 16.8633 17.3316L17.3054 17.2579C16.9369 16.0158 15.9896 15.1211 15.0633 15.1211C14.6738 15.1211 14.4106 15.4053 14.4106 15.8263C14.4106 16.0368 14.4738 16.5421 14.4738 16.9C14.4738 18.6579 13.0527 19.7947 13.0527 22.8053C13.0527 26.2263 15.6633 28.5 19.5896 28.5ZM19.8001 26.1842C18.2317 26.1842 17.1791 25.2263 17.1791 23.8053C17.1791 22.321 18.2422 21.7947 18.3791 20.8369C18.3896 20.7526 18.4317 20.7316 18.4948 20.7842C18.8843 21.1316 19.1264 21.5526 19.3264 22.0474C19.779 21.4369 19.9896 20.1316 19.8632 18.7737C19.8527 18.6895 19.9054 18.6474 19.979 18.679C21.8422 19.5421 22.7896 21.3842 22.7896 23.0368C22.7896 24.7105 21.8106 26.1842 19.8001 26.1842Z"
                                                fill="white"/>
                                        </g>
                                        <defs>
                                            <linearGradient id="paint0_linear_294_7890" x1="23.1497" y1="36.9867"
                                                            x2="11.2415"
                                                            y2="0.0417739" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#F44E17"/>
                                                <stop offset="1" stopColor="#FFAC00"/>
                                            </linearGradient>
                                            <clipPath id="clip0_294_7890">
                                                <rect width="18" height="18" fill="white"
                                                      transform="translate(11 10.5)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p><span style={{color: "white"}}>1</span>.00</p>
                                </div>
                                <p>Your current earn rate: <span style={{color: "white"}}>1 $DF / hour</span></p>
                            </div>
                            <button className={style.card_btn}>Start Mining</button>
                        </div>
                    </>
                }

            </div>
        </>
    )
}
