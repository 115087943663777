import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import HomeIcon from '../Icons/HomeIcon';
import VpnIcon from '../Icons/VpnIcon';
import FriendsIcon from '../Icons/FriendsIcon';
import TaskIcon from '../Icons/TaskIcon';

import '../Footer/Footer.css'
import {observer} from "mobx-react-lite";
import UserStore from "../../store/user-store";
import RouterIcon from "../Icons/RouterIcon";

export const Footer = observer(() => {
        const lang = UserStore.langCode
        const  location= useLocation()

    return (
            <div className='footer'>
                <NavLink to="/" className='footer_nav_bar_item'>
                    <HomeIcon location={location}/>
                    Home
                </NavLink>

                <NavLink to="/vpn" className='footer_nav_bar_item'>
                    <VpnIcon/>
                    VPN
                </NavLink>
                 {/*TODO UNCOMMENT ON NEXT RELEASE*/}
                {/*<NavLink to="/router" className='footer_nav_bar_item'>*/}
                {/*    <RouterIcon/>*/}
                {/*    Router*/}
                {/*</NavLink>*/}

                <NavLink to="/tasks" className='footer_nav_bar_item'>
                    <TaskIcon/>
                    Tasks
                </NavLink>

                <NavLink to="/friends" className='footer_nav_bar_item'>
                    <FriendsIcon/>
                    Friends
                </NavLink>


            </div>
        )
    }
)