import React from 'react'


export const OnboardingCard = ({img_scr, h1_text, p_text, index, cardIndex}) => {

  return (
    <div className='onboarding_card' style={index === cardIndex ? {opacity: 1} : {opacity: 0}}>
        <img src={img_scr} alt="" height={window.innerHeight - 300}/>
        <div className="onboarding_card_text">
            <h1>{h1_text}</h1>
            <p>{p_text}</p>
        </div>
    </div>
  )
}
