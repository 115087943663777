import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Welcomecard} from "../components/OnboardingCards/Welcomecard";
import {OnboardingCard} from "../components/OnboardingCards/OnboardingCard";
import {ConnectWallet} from "../components/OnboardingCards/ConnectWallet";
import UserStore from "../store/user-store";

const Onboardingpage = ({setOnboarding}) => {
    const hapticFeedBack = () => {
        window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");
    };
    const lang = UserStore.langCode
    const clickHanlder = () => {
        localStorage.setItem("onboarding", true)
        setOnboarding(true)
    }
    const onboarding_handlder = () => {
        window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");
        localStorage.setItem("onboarding", true);
        setOnboarding(true);
    };


    const onboarding_cards = [
        {
            img_scr: "/images/onboarding_icons/vpn.png",
            h1_text: lang === "ru" ? "Свобода и безопасность" : "Freedom and security",
            p_text: lang === "ru" ? "Обеспечим вам максимальную защиту и доступность любых ресурсов" : "Ensure you have maximum protection and availability of any resources"
        },
        {
            img_scr: lang === "ru" ? "/images/onboarding_icons/paymnets_ru.png" : "/images/onboarding_icons/paymnets_en.png",
            h1_text: lang === 'ru' ? "Оплачивайте удобным для вас способом" : "Pay in a convenient way",
            p_text: lang === "ru" ? "Крипта, PayPal, банковские карты" : "Crypto, PayPal, bank cards"
        },
        {
            img_scr: "/images/onboarding_icons/getrewards.png",
            h1_text: lang === "ru" ? "Получайте награды" : "Get rewards",
            p_text: lang === "ru" ? "Фармя очки $DF и приглашая друзей (скоро)" : "Farming $DF points and inviting friends (coming soon)"
        }
    ]
    const [cardindex, setIndex] = useState(0);

    return (
        <div className="container">
            <div className="onboarding">
                <div className="onboarding__main_content">
                    {cardindex === 0 ? (
                        <Welcomecard/>
                    ) : onboarding_cards.map((card, index) =>
                            <OnboardingCard
                                key={index}
                                index={index + 1}
                                cardIndex={cardindex}
                                img_scr={card.img_scr}
                                h1_text={card.h1_text}
                                p_text={card.p_text}
                            />
                    )}

                </div>
                <div className="onboarding_footer">
                    {cardindex <= 2 ? (
                        <button
                            onClick={() => {
                                setIndex(cardindex + 1);
                                hapticFeedBack();
                            }}
                            className="onboarding__btn"
                        >
                            <p className="onboarding__btn__text">
                                {cardindex === 0 ? (lang === "ru" ? "Начать" : "Start") : (lang === "ru" ? "Далее" : "Next")}
                            </p>
                            <p className="onboarding__btn__counter">
                                {cardindex === 0 ? "" : `${cardindex}/3`}
                            </p>
                        </button>
                    ) : <Link className="onboarding__btn" to='/' onClick={clickHanlder}>
                        <p className="onboarding__btn__text">
                            {lang === "ru" ? "Поехали" : "Lets go"}
                        </p>
                        <p className="onboarding__btn__counter">
                            {cardindex === 0 ? "" : `${cardindex}/3`}
                        </p>
                    </Link>

                    }
                </div>
            </div>
        </div>
    )
        ;
};

export default Onboardingpage;
