import React, {useEffect, useRef, useState} from 'react'
import {TonConnectButton, useIsConnectionRestored, useTonAddress} from '@tonconnect/ui-react'
import {NotProteced} from '../components/HomeProtect/NotProteced'
import {InitialCard} from '../components/MiningCards/InitialCard'
import {Protected} from "../components/HomeProtect/Protected";
import {PushWalletConnect} from "../components/Notification/PushWalletConnect";
import UserStore from "../store/user-store";
import {TrialBanner} from "../components/HomeProtect/Banners/TrialBanner";
import {observer} from "mobx-react-lite";
import {RouterBanner} from "../components/HomeProtect/Banners/RouterBanner";

export const Homepage = observer(() => {
    const TG_WEB_APP = window.Telegram.WebApp
    let userName = window.Telegram.WebApp.initDataUnsafe?.user?.username;
    let userID = window.Telegram.WebApp.initDataUnsafe?.user?.id;
    const userAvatarUrl = TG_WEB_APP.initDataUnsafe.user?.photo_url
    const userFriendlyAddress = useTonAddress();
    const BannerCarousel = observer(() => {
            const  containerRef = useRef(null);
            // const [currentIndex, setCurrentIndex] = useState(0); // Текущий индекс баннера
            const autoScrollRef = useRef(null); // Ссылка на таймер автопрокрутки
            const banners = [
                UserStore.can_activate_trial && !UserStore.is_trial_activated && <TrialBanner/>,
                // TODO UNCOMMENT ON NEXT RELEASE
                // <RouterBanner/>,
                UserStore.userActivePlanData ? UserStore.userActivePlanData.filter(el => el.user_plan_status).map(el => <Protected props={el}/>) : <NotProteced/>,

            ]

            const scrollToNextBanner = () => {
                if (!containerRef.current) return;
                const container = containerRef.current;
                const bannerWidth = container.clientWidth;
                // UserStore.incrementBannerIndex(banners.length)
                const nextIndex = (UserStore.banner_index + 1) % banners.length;
                UserStore.setBannerIndex(nextIndex)
                // const nextIndex = UserStore.banner_index;
                container.scrollTo({
                    left: nextIndex * bannerWidth,
                    behavior: 'smooth',
                });
            };

            useEffect(() => {
                // Запуск автопрокрутки
                autoScrollRef.current = setInterval(scrollToNextBanner, 5000);

                // Очистка таймера при размонтировании компонента
                return () => clearInterval(autoScrollRef.current);
            }, [UserStore.banner_index]);

            const handlePause = () => {
                clearInterval(autoScrollRef.current); // Остановка автопрокрутки
            };

            const handleResume = () => {
                autoScrollRef.current = setInterval(scrollToNextBanner, 5000); // Возобновление автопрокрутки
            };

        useEffect(() => {
            // Восстановление состояния баннера при возвращении на страницу
            const savedIndex = UserStore.banner_index;
            if (savedIndex) {
                UserStore.setBannerIndex(savedIndex);
            }
        }, []);


        return (
            <div ref={containerRef}
                 className="user_data__items__item"
                 onMouseEnter={handlePause}
                 onMouseLeave={handleResume}
                 onTouchStart={handlePause}
                 onTouchEnd={handleResume}
            >
                {banners.map((banner, index) => (banner))}
            </div>
        )
    });
    return (
        <div className='home_page'>
            {userFriendlyAddress &&
                <PushWalletConnect push_text={"Wallet connected"}
                                   push_image={<svg xmlns="http://www.w3.org/2000/svg" width="19"
                                                    height="18" viewBox="0 0 19 18" fill="none">
                                       <g clipPath="url(#clip0_580_9669)">
                                           <path
                                               d="M16.7141 4.875H3.3125C3.00312 4.875 2.75 4.62188 2.75 4.3125C2.75 4.00312 3.00312 3.75 3.3125 3.75H16.8125C17.1219 3.75 17.375 3.49688 17.375 3.1875C17.375 2.25586 16.6191 1.5 15.6875 1.5H2.75C1.50898 1.5 0.5 2.50898 0.5 3.75V15C0.5 16.2445 1.50898 17.25 2.75 17.25H16.7141C17.6984 17.25 18.5 16.4941 18.5 15.5625V6.5625C18.5 5.63086 17.6984 4.875 16.7141 4.875ZM15.125 12.1875C14.5027 12.1875 14 11.6848 14 11.0625C14 10.4402 14.5027 9.9375 15.125 9.9375C15.7473 9.9375 16.25 10.4402 16.25 11.0625C16.25 11.6848 15.7473 12.1875 15.125 12.1875Z"
                                               fill="white"/>
                                       </g>
                                       <defs>
                                           <clipPath id="clip0_580_9669">
                                               <rect width="18" height="18" fill="white"
                                                     transform="translate(0.5)"/>
                                           </clipPath>
                                       </defs>
                                   </svg>
                                   }/>
            }
            <div className="user_data">
                <div className="user_data__header">
                    <div className="user_data__header__user_profile">
                        <div className='user_image'>
                            {userAvatarUrl ? <img src={userAvatarUrl} className='user_image__name' alt="User Avatat"/> :
                                <p className='user_image__name'>{userName ? userName[0].toUpperCase() : 'U'}</p>}
                        </div>
                        <div className="user_data__header__user_profile__user_name">
                            <p>{userName ? userName : 'Username'}</p>
                            <button onClick={() => UserStore.setSettingWindow(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path
                                        d="M10.9648 23.9998H13.0207C13.6039 23.9998 14.0413 23.6498 14.1725 23.0812L14.7703 20.5295C15.2515 20.3691 15.7035 20.1942 16.1118 19.99L18.3426 21.3606C18.8092 21.6522 19.3779 21.6085 19.7716 21.2148L21.2151 19.7713C21.6087 19.3776 21.667 18.7798 21.3463 18.2987L19.9903 16.0824C20.1944 15.6741 20.384 15.2221 20.5152 14.7993L23.096 14.1869C23.6501 14.0702 23.9854 13.6328 23.9854 13.035V11.0228C23.9854 10.4542 23.6501 10.0168 23.096 9.90012L20.5443 9.27314C20.384 8.77741 20.1798 8.33997 20.0194 7.97545L21.3755 5.71545C21.6817 5.23426 21.6525 4.68021 21.2442 4.27195L19.7716 2.82844C19.3633 2.46392 18.8675 2.39103 18.3864 2.65349L16.1118 4.05324C15.7181 3.84911 15.2661 3.67412 14.7703 3.51373L14.1725 0.918355C14.0413 0.349703 13.6039 -0.000244141 13.0207 -0.000244141H10.9648C10.3815 -0.000244141 9.94409 0.349703 9.82746 0.918355L9.21506 3.48459C8.74847 3.64497 8.26731 3.81993 7.87362 4.03864L5.61362 2.65349C5.11786 2.39103 4.62213 2.44935 4.21386 2.82844L2.74118 4.27195C2.33292 4.68021 2.30377 5.23426 2.60997 5.71545L3.96598 7.97545C3.80559 8.33997 3.60146 8.77741 3.45565 9.27314L0.904008 9.90012C0.335356 10.0168 0 10.4542 0 11.0228V13.035C0 13.6328 0.335356 14.0702 0.904008 14.1869L3.48482 14.7993C3.60146 15.2221 3.79099 15.6741 3.99512 16.0824L2.63911 18.2987C2.31834 18.7798 2.37666 19.3776 2.77036 19.7713L4.21386 21.2148C4.60752 21.6085 5.17617 21.6522 5.65733 21.3606L7.88822 19.99C8.28189 20.1942 8.74847 20.3691 9.21506 20.5295L9.82746 23.0812C9.94409 23.6498 10.3815 23.9998 10.9648 23.9998ZM12 16.0095C9.78371 16.0095 7.99029 14.1869 7.99029 11.9852C7.99029 9.79805 9.78371 7.99006 12 7.99006C14.2017 7.99006 15.9951 9.79805 15.9951 11.9852C15.9951 14.1869 14.2017 16.0095 12 16.0095Z"
                                        fill="white"/>
                                </svg>
                            </button>
                            {userID === 541456675 &&
                                <p style={{fontSize: "3px"}}>Самая красивая девушка на планете замеля ❤️</p>}
                        </div>
                    </div>

                    <TonConnectButton/>

                </div>
                <div className="banners_main">
                    <BannerCarousel/>
                    <InitialCard/>
                </div>
            </div>

        </div>


    )
})

