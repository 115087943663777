import {makeAutoObservable} from "mobx";



class PaymentStore {
    paymentMethod = null
    plan_id = null
    isPaymentSelected = false
    isPaymentProcessed = false
    paymentStatus = false
    paymentPush = false
    email = undefined
    isValidEmail = false
    tx_id = false
    isPaymentUrlOpened = false
    paymentUrl = null
    paymentTimer = 10
    payment_amount = null
    payment_currency = null
    payment_target_plan = null
    isStatusWindowOpened = true



    constructor() {
        makeAutoObservable(this)
    }

    setIsStatusWindowOpened(state) {
        this.isStatusWindowOpened = state
    }
    setPaymentMethod(paymentMethod) {
        this.paymentMethod = paymentMethod;
        this.isPaymentSelected = true;
    }

    setPaymentStatus(state){
        this.paymentStatus = state
    }

    setPaymentProcessed(state) {
        this.isPaymentProcessed = state
    }

    setIsPaymentSelected(isPaymentSelected) {
        this.isPaymentSelected = isPaymentSelected;
    }

    clearPaymentMethod() {
        this.paymentMethod = null
    }
    setPlanId(plan_id) {
        this.plan_id = plan_id;
    }

    setPaymentPush(state) {
        this.paymentPush = state;
    }

    setEmail(email) {
        this.email = email;
    }

    setIsValidEmail(state) {
        this.isValidEmail = state
    }

    setPaymentUrl(url) {
        this.paymentUrl = url;
    }

    setTxId(txId) {
        this.tx_id = txId;
    }

    setIsPaymentUrlOpened (state) {
        this.isPaymentUrlOpened = state
    }

    setPaymentTimer(time) {
        this.paymentTimer = time;
    }

    resetTimer() {
        this.paymentTimer = 10
    }

}

export default new PaymentStore();