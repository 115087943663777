import {makeAutoObservable, makeObservable} from "mobx";

class RouterStore {
    routerIntro = JSON.parse(localStorage.getItem("routerIntro"));
    routerPayWallIntro = false

    constructor() {
        makeAutoObservable(this)
    }

    setRouterIntro() {
        this.routerIntro = true;
        console.log(this.routerIntro)
        localStorage.setItem("routerIntro", JSON.stringify(true))
    }

    setRouterPayWallIntro() {
        this.routerPayWallIntro = true
    }
}
export default new RouterStore();