import {observer} from "mobx-react-lite";
import styles from "./PassOfferBanner.module.css";
import PassOfferPng from "../../Icons/png/PassOffer.png"
import UserStore from "../../../store/user-store";
import {useEffect, useState} from "react";

const PassOfferBanner = observer(() => {
    const [isVisible, setIsVisible] = useState(true);


    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            UserStore.closePassOffer()
        }, 500);
    }

    return (
        (UserStore.passVpnOffer && !UserStore.isUserClosePassVpnOffer) && <div className={`${styles.pass_offer_banner} ${!isVisible ? styles.fade_out : ''}`}>
            <div className={styles.pass_offer_content}>
                <h1>Вечная защита.<br/>Абсолютная свобода.</h1>
                <button>Перейти на Lifetime Pass</button>
            </div>
            <img src={PassOfferPng} alt="Pass offer"/>
            <svg xmlns="http://www.w3.org/2000/svg" width="354" height="119" viewBox="0 0 354 119" fill="none">
                <g opacity="0.7" filter="url(#filter0_f_2035_13775)">
                    <ellipse cx="177" cy="-4.00391" rx="117" ry="63" fill="#EB6A04"/>
                </g>
                <g filter="url(#filter1_f_2035_13775)">
                    <ellipse cx="177" cy="-10.0039" rx="70" ry="30" fill="#FF8F21"/>
                </g>
                <defs>
                    <filter id="filter0_f_2035_13775" x="0" y="-127.004" width="354" height="246"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="30" result="effect1_foregroundBlur_2035_13775"/>
                    </filter>
                    <filter id="filter1_f_2035_13775" x="77" y="-70.0039" width="200" height="120"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_2035_13775"/>
                    </filter>
                </defs>
            </svg>

            <button style={{position: "absolute", zIndex: 1000, right: 10, top: 10}} onClick={() => handleClose()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clipPath="url(#clip0_1968_41782)">
                        <path
                            d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
                            fill="#2A2725"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M13.6664 7.19639L7.19576 13.667L6.33301 12.8042L12.8036 6.33369L13.6664 7.19639Z"
                              fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M12.8036 13.667L6.33301 7.19639L7.19576 6.33369L13.6664 12.8042L12.8036 13.667Z"
                              fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1968_41782">
                            <rect width="20" height="20" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </button>
        </div>
    )
})

export default PassOfferBanner;