import UserStore from "../../../store/user-store";
import PaymnetStore from "../../../store/paymnet-store";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const TG_WEB_APP = window.Telegram.WebApp


export const cryptomusCreateInvoice = async (planId) => {
    const body = {
        "user_id": UserStore.userId,
        "plan_id": planId,
    }
    if (UserStore.partnerId) {
        body["partner_id"] =UserStore.partnerId
    }
    axios.post(`${apiUrl}/v2/payment/cryptomus_invoice`, {
        ...body
    }, {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }
    }).then(data => {
        if (data.status === 200 && PaymnetStore.isPaymentProcessed) {
            PaymnetStore.setPaymentUrl(data.data?.payment_url)
            PaymnetStore.setTxId(data.data?.order_id)
            PaymnetStore.setIsPaymentUrlOpened(true)
            TG_WEB_APP.openLink(data.data?.payment_url)

        }
    })
}