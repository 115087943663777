import routerStore from "../../../store/router-store";
import styles from "./RouterPaywall.module.css";
import RouterSvg from "../../Icons/svg/RouterSvg";
import {observer} from "mobx-react-lite";
import {Payments} from "../../Payments/Payments";
import {BuyPlan} from "../../VpnPlanCards/PassCard/BuyPlan";

export const RouterPaywall = observer(() => {

    const PaywallIntro = () => {
        return (
            <>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%"
                }}>
                    <div className={styles.paywall_heading}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none">
                            <g clipPath="url(#clip0_1846_15518)">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M6.24269 1.76884C5.20711 2.2272 4.13596 2.60271 3.03966 2.89159L2.67676 2.98235V5.86928C3.43593 5.60926 4.18503 5.32274 4.92251 5.01027C6.49161 4.3663 8.00697 3.61034 9.4552 2.74896C10.9071 3.61335 12.4264 4.37166 14.0002 5.01738C14.7306 5.32545 15.4723 5.60807 16.2239 5.86472V2.98235L15.861 2.89159C14.7631 2.60446 13.6905 2.23061 12.6532 1.7736C11.6346 1.3372 10.651 0.824493 9.71161 0.239984L9.45029 0.0727539L9.18417 0.235154C8.24474 0.819726 7.26139 1.33243 6.24269 1.76884Z"
                                      fill="white"/>
                                <path
                                    d="M4.92197 5.00985C3.3303 5.68428 1.68444 6.23771 0 6.66491V11.847C0 16.1933 2.30879 19.1933 4.68502 21.1362C5.87409 22.1082 7.06924 22.8052 7.97304 23.2586C8.42418 23.485 8.79986 23.6491 9.06175 23.7553C9.18277 23.8043 9.27789 23.8404 9.34382 23.8642H9.34791L9.4635 23.9027C9.74311 23.8009 11.9987 22.9497 14.241 21.1201C16.6185 19.1803 18.9282 16.189 18.9282 11.847V6.66508C17.2418 6.2404 15.5937 5.68935 13.9997 5.01696C12.4259 4.37126 10.9066 3.61293 9.45466 2.74854C8.00642 3.60991 6.49107 4.36588 4.92197 5.00985Z"
                                    fill="url(#paint0_linear_1846_15518)"/>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_1846_15518" x1="11.6459" y1="23.8949" x2="4.00468"
                                                y2="2.68263" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#F44E17"/>
                                    <stop offset="1" stopColor="#FFAC00"/>
                                </linearGradient>
                                <clipPath id="clip0_1846_15518">
                                    <rect width="19" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <h2>DeGuard Router</h2>
                        <h1>Privacy for every home.</h1>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 9
                    }}>
                        <RouterSvg/>
                        <div className={styles.paywall_router_details}>
                            <p>30+ locations with 99% uptime available for VLESS connection</p>
                            <p>Control via MiniApp</p>
                            <p>WI-FI 6, 5G, 2x1.3GHz processor</p>
                        </div>
                    </div>
                </div>
                <div className={styles.paywall_btn}>
                    <p>Starting at $149 with a lifetime subscription</p>
                    <button>Buy</button>
                </div>
            </>
        )
    }

    return (
        <div className={styles.paywall_wrapper}>
            <PaywallIntro/>

        </div>
    )
})
