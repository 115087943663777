import React, {useEffect, useState} from 'react';
import styles from './Timer.module.css';
import UserStore from "../../../store/user-store";
import PaymnetStore from "../../../store/paymnet-store";
import {observer} from "mobx-react-lite";

function Timer() {
    useEffect(() => {
        if (PaymnetStore.paymentTimer <= 0) return;

        const intervalId = setInterval(async () => {
            PaymnetStore.setPaymentTimer(PaymnetStore.paymentTimer - 1)
        }, 1000);

        return () => clearInterval(intervalId);
    }, [PaymnetStore.paymentTimer]);


    return (
        <div className={styles.timer}>
            <span style={{
                color: 'white',
                fontFamily: "VK Sans Display, sans-serif"
            }}>
                {PaymnetStore.paymentTimer !== 0 ?
                    (UserStore.langCode === 'ru' ?
                        <p>Вы будете перенаправлены на платежную <br/> систему, через <span
                            style={{'color': '#eb8b0c'}}>{PaymnetStore.paymentTimer}</span></p>
                        :
                        <p>You will be redirected to the <br/> payment system in <span
                            style={{'color': '#eb8b0c'}}>{PaymnetStore.paymentTimer}</span></p>) :
                    ""
                }
            </span>

        </div>
    );
}

export default observer(Timer);
