import React, {useEffect, useRef, useState} from "react";
import "../../pages/RouterPage.css";
import {observer} from "mobx-react-lite";
import RouterStore from "../../store/router-store";

export const RouterIntro = observer(() => {
    const [scrollY, setScrollY] = useState();
    const TG_WEB_APP = window.Telegram.WebApp


    useEffect(() => {
        if (TG_WEB_APP.version >= "8.0" && TG_WEB_APP.platform !== 'desktop' ) {
            TG_WEB_APP.requestFullscreen()
        }

        const app = document.querySelector(".app")
        document.body.style.overflowY = "scroll"
        app.style.backgroundImage = "none"
        setTimeout(() => {
            app.classList.remove("fadeIn")
        }, 300)

        return () => {
            document.body.style.overflowY = "hidden"
            app.removeAttribute("style")
            if (TG_WEB_APP.version >= "8.0" && TG_WEB_APP.platform !== 'desktop') {
                TG_WEB_APP.exitFullscreen()
            }

        }
    }, [])
    useEffect(() => {
        const handleScroll = () => {
            const timeout = setTimeout(() => setScrollY(window.scrollY), 50);
            return () => clearTimeout(timeout);

        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const overlay = document.querySelector(".overlay");
            const img = document.querySelector(".router_content");


            const scrollFraction = window.scrollY / window.innerHeight;
            const scaleValue = Math.min(scrollFraction, 2);

            img.style.transform = `scale(${scaleValue}) translateX(${scaleValue / 2}px)`;
            overlay.style.background = `rgba(0, 0, 0, ${Math.max(1 - scrollFraction, 0)})`;

        };

        document.addEventListener("scroll", handleScroll);


        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const texts = document.querySelectorAll('.scroll-text');
    const windowHeight = window.innerHeight;
    const offset = windowHeight * 2;
    window.addEventListener('scroll', () => {
        const scrollY = window.scrollY;

        texts.forEach((text, index) => {
            const start = offset + index * windowHeight
            const end = start + windowHeight; // окончание увеличения

            if (scrollY >= start && scrollY < end) {
                text.classList.add('active'); // Применяем увеличение
            } else {
                text.classList.remove('active'); // Убираем увеличение
            }
        });
    });
    const lottieRef = useRef();
    useEffect(() => {
        if (lottieRef.current) {
            lottieRef.current.setSpeed(0.5); // Устанавливаем скорость воспроизведения
        }
    }, []);

    return (
        <div className="router_page">
            <div className="router_card_wrapper">
                <div className="router_card">
                    <img src="https://framerusercontent.com/images/euxJ022g9T9oWXmrSNtWZUw.png?scale-down-to=1024"/>
                    <div>
                        <h1 style={{marginBottom: 10}}>Deguard Router <br/> with VPN on board</h1>
                        <p style={{marginBottom: 20, opacity: 0.5}}>Experience unlimited freedom on the Internet
                            right in your home</p>
                        <div className="router_card_last_block">
                            <div>
                                <p style={{opacity: 0.7}}>Limited edition</p>
                                <h1>From $199</h1>
                            </div>
                            <button onClick={() => {
                                RouterStore.setRouterIntro()
                            }}>Router Preorder
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="fist_block_wrapper">*/}
            {/*    <DeGuardIcon/>*/}
            {/*    <h1>DeGuard VPN Router</h1>*/}
            {/*    <Lottie*/}
            {/*        lottieRef={lottieRef} animationData={swipe}*/}
            {/*        speed={500}*/}
            {/*    />*/}
            {/*    <p>Swipe</p>*/}

            {/*    <button onClick={() => {*/}
            {/*        window.scrollTo({*/}
            {/*            top: document.body.scrollHeight, // высота всего документа*/}
            {/*            behavior: 'smooth' // плавная прокрутка*/}
            {/*        });*/}
            {/*    }}>Skip intro*/}
            {/*    </button>*/}
            {/*</div>*/}

            <div style={{height: "100dvh"}}>
                <div className="image_wrapper">
                    <div className="overlay"></div>
                    <div className="router_content">
                        <h1 style={{
                            textAlign: 'center',
                            // background: "linear-gradient(90deg, #ff7a18, #ff0042 70%)",
                            // webkitBackgroundClip: "text",
                            // webkitTextFillColor: "transparent"
                        }}>Privacy for every home</h1>
                        <img src="/images/router.avif" alt="asa"/>
                    </div>
                </div>
            </div>
            <div className="wrapper1">
                <div className="container1">
                    <div className="scroll-text">
                        <div className="scroll-text_text">
                            <h1>5G range</h1>
                            <p>Efficient wall penetration reach more rooms</p>
                        </div>
                    </div>
                    <div className="scroll-text">
                        <div className="scroll-text_text">
                            <h1>Wi-Fi 6</h1>
                            <p>Buffer-free 4K movie streaming</p>
                        </div>
                    </div>
                    <div className="scroll-text">
                        <div className="scroll-text_text">
                            <h1>VPN Protection</h1>
                            <p>160 countries in one device </p>
                        </div>
                    </div>
                    <div className="scroll-text">
                        <div className="scroll-text_text">
                            <h1>1.3GHz processor</h1>
                            <p>High-performance, long-lasting, stable connection</p>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
})