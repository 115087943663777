import {makeAutoObservable, observable} from "mobx";

class UserStore {

    userId = null;
    partnerId = null;
    langCode = '';
    userPlanData = null;
    userActivePlanData = null;
    servers_ping = null;
    settingWindow = false
    userConfigs = null
    can_activate_trial = null
    is_trial_activated = false
    telegramId = null;
    banner_index = 0;
    passVpnOffer = null;
    isUserClosePassVpnOffer = false;

    visual_effects = {
        snow_effects: JSON.parse(localStorage.getItem("snow_effects")) === null
            ? (() => {
                localStorage.setItem("snow_effects", JSON.stringify(true));
                return true;
            })()
            : JSON.parse(localStorage.getItem("snow_effects"))
    };


    constructor() {
        makeAutoObservable(this)
    }

    setVisualEffectsSnowEffects() {
        this.visual_effects.snow_effects = !this.visual_effects.snow_effects;
        localStorage.setItem("snow_effects", JSON.stringify(this.visual_effects.snow_effects)); // Сохраняем как строку JSON
    }
    setPassVpnOffer(state) {
        this.passVpnOffer = state
    }

    closePassOffer () {
        this.isUserClosePassVpnOffer = true;
    }

    setTelegramId(telegramId) {
        this.telegramId = telegramId;
    }

    setBannerIndex(index) {
        this.banner_index = index;
    }

    incrementBannerIndex(bannerLength) {
        this.banner_index = (this.banner_index + 1) % bannerLength; // например, у нас 3 баннера
    }

    setUserConfigs(configs) {
        this.userConfigs = {...this.userConfigs, ...configs}
    }

    incrementConfig(indexActiveServer, protocolId, data) {
        this.userConfigs.server_id[indexActiveServer][protocolId]['config_text'] = data
    }

    setSettingWindow(state) {
        this.settingWindow = state;
    }
    setUserId(id) {
        this.userId = id;
    }

    setLangCode(langCode) {
        this.langCode = langCode
        localStorage.setItem('lang_code', langCode)
    }

    setPartner(partnerId) {
        this.partnerId = partnerId;
    }

    setUserPlanData(planData) {
        if (planData) {
            this.userPlanData = planData;
            const active_plans = planData.filter(el => el.user_plan_status);
            if (active_plans.length > 0) this.userActivePlanData = active_plans;
        } else {
            this.userPlanData = null;
        }
    }

    setPlanDataAfterPayment(planData) {
        if (planData) {
            if (this.userActivePlanData) {
                this.userActivePlanData = [...this.userActivePlanData, planData]
            } else  {
                this.userActivePlanData = [planData]
            }

        }
    }

    setServersPing(data) {
        this.servers_ping = {
            ...data
        }
    }
    setIsTrailActivated(state) {
        this.can_activate_trial = state
    }
    setTrailActivated(state) {
        this.is_trial_activated = state
    }
}

export default new UserStore();