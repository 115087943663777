import React from 'react'
import { Link } from 'react-router-dom'
import style from './NotProteced.module.css'
import ProtectLogoHome from '../Icons/svg/ProtectLogoHome'
import UserStore from "../../store/user-store";

export const NotProteced = () => {
    const lang = UserStore.langCode
  return (
    <div className={style.card} id="bnner">
        <div className={style.card__content}>
            <div className={style.card__content__text}>
                <div className={style.protect_status}></div>
                {lang === "ru" ? <h1>Вы не защищены</h1> :<h1>You are not protected</h1>}
            </div>
            {lang === "ru" ?
                <p>Купить подписку с <span style={{color: "white"}}>20% скидкой</span></p> :
                <p>Buy plan with <span style={{color: "white"}}>20% discount</span></p>}

        </div>
        <Link className={style.card__btn} to='/vpn'>{lang === "ru" ? "Купить подписку" : "Buy plan"}</Link>
    <ProtectLogoHome className={style.card__logo}/>
    </div>
  )
}
