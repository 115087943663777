import styles from "./WgBanner.module.css"

export const WgBanner = () => {
    return (
        <div className={styles.wrapper}>
            <svg style={{flexShrink: 0}} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path
                    d="M3.25818 23.2164H20.7418C22.7636 23.2164 24 21.8054 24 19.9582C24 19.42 23.84 18.8527 23.5491 18.3436L14.7927 3.14361C14.1673 2.06727 13.1054 1.5 12 1.5C10.8946 1.5 9.81819 2.06727 9.20727 3.14361L0.450909 18.3436C0.145454 18.8673 0 19.42 0 19.9582C0 21.8054 1.23637 23.2164 3.25818 23.2164ZM3.27272 21.1654C2.55999 21.1654 2.09456 20.5836 2.09456 19.9582C2.09456 19.7691 2.13817 19.5363 2.23999 19.3473L10.9818 4.1327C11.2 3.75453 11.6073 3.56543 12 3.56543C12.3782 3.56543 12.7709 3.75453 13.0036 4.1327L21.7454 19.3618C21.8473 19.5509 21.8909 19.7691 21.8909 19.9582C21.8909 20.5836 21.4254 21.1654 20.7127 21.1654H3.27272Z"
                    fill="#FF0004"/>
                <path
                    d="M12 15.4636C12.6109 15.4636 12.96 15.1291 12.9746 14.46L13.1637 8.49635C13.1782 7.85633 12.6546 7.3618 11.9855 7.3618C11.2873 7.3618 10.8073 7.84179 10.8218 8.48181L10.9818 14.46C10.9964 15.1145 11.3454 15.4636 12 15.4636ZM12 19.2745C12.7127 19.2745 13.3237 18.7218 13.3237 17.9945C13.3237 17.2672 12.7273 16.7145 12 16.7145C11.2727 16.7145 10.6618 17.2818 10.6618 17.9945C10.6618 18.7072 11.2873 19.2745 12 19.2745Z"
                    fill="#FF0004"/>
            </svg>
            <p>WireGuard заблокирован в России. Пожалуйста, используйте Vless</p>
        </div>
    )
}