import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import PaymnetStore from "../../../store/paymnet-store";

const apiUrl = process.env.REACT_APP_API_URL;

const fetchPaymentStatus = async () => {
    return axios.get(`${apiUrl}/v2/payment/tx_status/?tx_id=${PaymnetStore.tx_id}&payment_id=${PaymnetStore.paymentMethod}`, {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }
    })
}


export const usePaymentStatus = (txId) => {
    const {data, isLoading, isSuccess, refetch} = useQuery({
        queryKey: ['tx status'],
        queryFn: () => fetchPaymentStatus(txId),
        select: (data) => data.data,
        refetchInterval: 5000,
        refetchIntervalInBackground: true,
        enabled: Boolean(txId),
    })

    return {data, isLoading, isSuccess, refetch}
}