import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';

const TimerToPoints = ({targetDate}) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // Функция для вычисления оставшегося времени
    function calculateTimeLeft() {
        const difference = new Date(targetDate) - new Date();
        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            return null; // Таймер истёк
        }
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Очистка интервала
    }, [targetDate]);

    if (!timeLeft) {
        return <h2>Time's up!</h2>;
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", zIndex: 10, position: "absolute", gap: "10px"}}>

            <h1 style={{fontSize: "16px"}}>$DF points will show up in</h1>
            <div style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',

            }}>
                {Object.entries(timeLeft).map(([unit, value], index) => (
                    <motion.div
                        key={index}
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                        style={{
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            color: 'white',
                        }}
                    >
                        <p style={{fontSize: '30px', margin: '0'}}>{value}</p>
                        <p style={{margin: '0', fontSize: '16px', textTransform: 'capitalize'}}>{unit}</p>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default TimerToPoints;
