import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import PaymnetStore from "../../../store/paymnet-store";
import {createPlan} from "../createPlan";
import UserStore from "../../../store/user-store";
const apiUrl = process.env.REACT_APP_API_URL;

export const TelegramPayment = async (planId, userId, langCode) => {
    const TG_WEB_APP = window.Telegram.WebApp

    const tgInvoiceHandler = (event) => {
        if (event.status === 'paid') {
            createPlan(PaymnetStore.plan_id, UserStore.userId, UserStore.partnerId, PaymnetStore.paymentMethod)
            PaymnetStore.setPaymentStatus('paid')
        }
        else {
            PaymnetStore.setPaymentProcessed(false)
        }
    }

    window.Telegram.WebApp.onEvent('invoiceClosed', tgInvoiceHandler);

    const body = {
        plan_id: planId,
        user_id: userId,
        lang_code: langCode,
    }
    console.log(body)
    if (UserStore.partnerId) body.partner_id = UserStore.partnerId;

    axios.post(`${apiUrl}/miniapp/create_invoice`, body, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }
    }).then((data =>
        TG_WEB_APP.openInvoice(data?.data?.invoice_link))
    )

};




// export function CreateTelegramInvoice(planId, userId, langCode) {
//
//     const {data, isSuccess, isLoading} = useQuery(
//         {
//             queryKey: ["create tg invoice"],
//             queryFn:  () => createInvoice(planId, userId, langCode),
//             retry: 0,
//             // enabled:
//         }
//     )
//
//     return {data, isSuccess, isLoading}
//
// }