import styles from "./PaymentStatus.module.css";
import UserStore from "../../../store/user-store";
import React from "react";
import {createPortal} from "react-dom"
import {observer} from "mobx-react-lite";
import PaymnetStore from "../../../store/paymnet-store";

export const PaymentStatus = observer(() => {
        const TG_WEB_APP = window.Telegram.WebApp;
        const lang_code = UserStore.langCode;
        const success_status = ['subscription-active', 'completed', 'paid', 'paid_over'];
        const IS_TX_INITIALIZE = ['in-progress', 'process', 'confirm_check', 'check'].includes(PaymnetStore.paymentStatus);


        const SuccessTx = observer(() => {
            return (
                <>
                    <div className={styles.status_content}>
                        <svg style={{marginBottom: '10px'}} xmlns="http://www.w3.org/2000/svg"
                             width="111" height="112"
                             viewBox="0 0 111 112"
                             fill="none">
                            <rect y="0.5" width="111" height="111" rx="55.5" fill="#3EB350"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M80.7273 41.6783L51.9947 78.6201L30.2727 58.3462L35.3836 52.8703L51.1139 67.5519L74.8147 37.0796L80.7273 41.6783Z"
                                  fill="white"/>
                        </svg>
                        <p style={{
                            fontFamily: "VK Sans Display",
                            fontSize: "16px",
                            opacity: "0.5",
                        }}>{lang_code === "ru" ? "Успешная оплата" : "Payment successful"}</p>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "10px",
                        width: '100%'
                    }}>
                        <button style={{
                            color: 'black',
                            backgroundColor: 'white',
                            fontSize: "16px",
                            fontWeight: 500
                        }} onClick={() => {
                            PaymnetStore.setPaymentStatus(null)
                            PaymnetStore.setIsStatusWindowOpened(false)
                        }}>{UserStore.langCode === 'ru' ? 'Перейти к VPN' : 'Go to VPN'}</button>
                    </div>
                </>
            )
        })
        const UnSuccessTx = observer(() => {
            return (
                <>
                    <div className={styles.status_content}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="203" height="113"
                             viewBox="0 0 203 113"
                             fill="none">
                            <g clipPath="url(#clip0_5_60)">
                                <path
                                    d="M149.687 45.6262L125.222 89.3581C123.923 91.6184 123.188 94.3304 123.188 96.8728C123.188 99.6398 123.866 102.223 125.093 104.444H52.279C40.4703 104.444 34.5377 98.6244 34.5377 86.985V43.5357H151.08C150.564 44.1781 150.099 44.8778 149.687 45.6262ZM57.6467 65.6275C54.3132 65.6275 52.1093 67.8314 52.1093 71.0516V81.5044C52.1093 84.6683 54.3132 86.9286 57.6467 86.9286H71.4895C74.8231 86.9286 77.0265 84.6683 77.0265 81.5044V71.0516C77.0265 67.8314 74.8231 65.6275 71.4895 65.6275H57.6467ZM167.315 25.4553V30.7099H34.5377V25.4553C34.5377 13.8161 40.4703 7.94 52.279 7.94H149.574C161.439 7.94 167.315 13.8161 167.315 25.4553Z"
                                    fill="white"/>
                                <path
                                    d="M169.575 49.3552L194.097 93.087C194.775 94.3304 195.114 95.5734 195.114 96.8727C195.114 101.11 192.119 104.444 187.486 104.444H138.5C133.867 104.444 130.929 101.11 130.929 96.8727C130.929 95.5734 131.268 94.3304 131.945 93.1439L156.411 49.3552C157.823 46.7563 160.422 45.5132 163.021 45.5132C165.564 45.5132 168.106 46.7563 169.575 49.3552ZM158.219 91.0533C158.219 93.7084 160.422 95.8559 163.021 95.8559C165.677 95.8559 167.767 93.7084 167.767 91.0533C167.824 88.5105 165.677 86.3071 163.021 86.3071C160.422 86.3071 158.219 88.454 158.219 91.0533ZM159.123 62.9718L159.631 79.0749C159.687 80.9958 161.044 82.352 163.021 82.352C164.999 82.352 166.411 80.9958 166.468 79.0749L166.92 62.9718C166.976 60.6556 165.394 59.0737 163.021 59.0737C160.648 59.0737 159.066 60.6556 159.123 62.9718Z"
                                    fill="#FF7C7C"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_5_60">
                                    <rect width="202.105" height="112.042" fill="white"
                                          transform="translate(0.75 0.142822)"/>
                                </clipPath>
                            </defs>
                        </svg>

                        <div className={styles.status_content_text}>
                            <h1>
                                {lang_code === "ru" ? "При оплате возникла ошибка" : "Your payment has failed"}
                            </h1>
                            <p>{lang_code === "en" ? "Вы можете попробовать еще раз, или связаться с поддержкой" : "You can retry the payment below to continue this"}</p>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "10px",
                        width: '100%'
                    }}>
                        <button style={{backgroundColor: '#232426', color: 'white'}} onClick={() => {
                            TG_WEB_APP.openTelegramLink('https://t.me/DeGuardVPN_support')
                        }}>{lang_code === "ru" ? "Поддержка" : "Contact support"}</button>
                        <button
                            style={{color: 'black', backgroundColor: "white"}}
                            onClick={() => {
                                PaymnetStore.setPaymentStatus(false)
                                PaymnetStore.setIsPaymentUrlOpened(false)
                                PaymnetStore.setPaymentProcessed(false)
                                PaymnetStore.setTxId(null)
                                PaymnetStore.setPaymentUrl(null)
                                PaymnetStore.resetTimer()
                                PaymnetStore.setIsStatusWindowOpened(false)
                            }}>

                            {lang_code === "ru" ? "Попробовать снова" : "Try again"}
                        </button>
                    </div>
                </>
            )
        })

        return createPortal(
            PaymnetStore.paymentStatus && (<>
                <div className={styles.payment_status}>
                    <div className={styles.payment_status_content}>
                        {success_status.includes(PaymnetStore.paymentStatus) ? <SuccessTx/> : <UnSuccessTx/>}
                    </div>
                </div>
            </>), document.body
        )
    }
)